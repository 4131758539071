import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FailedComponent } from './failed/failed.component';
import { ProfileComponent } from './profile/profile.component';
import { BrowserUtils } from '@azure/msal-browser';
import { MsalGuard } from '@azure/msal-angular';
import { ProductOverviewComponent } from './product-overview/product-overview.component';
import { ProductComponent } from './product/product.component';
import { PortalComponent } from './portal/portal.component';

const routes: Routes = [
	{
		path: 'profile',
		component: ProfileComponent,
		canActivate: [MsalGuard]
	},
	{
		path: '',
		component: ProductOverviewComponent
	},
	{
		path: 'login-failed',
		component: FailedComponent
	},
	{
		path: 'product/:slug',
		component: ProductComponent
	},
	{
		path: 'portal',
		component: PortalComponent
	}
];


@NgModule({
	imports: [RouterModule.forRoot(routes, {
		// Don't perform initial navigation in iframes or popups
		initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled', // Set to enabledBlocking to use Angular Universal,
		useHash: false, // adds # to URL and makes life easier for server config,
		enableTracing: false // loads of debug information in the console
	})],
	exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, Inject, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { filter, Subject, takeUntil } from 'rxjs';
import { Product } from 'src/types/product.type';
import { LoggerService } from '../logger.service';
import { ProductService } from '../product.service';

@Component({
	selector: 'shsis-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
	products: Product[] = [];

	loggedIn = false;
	destroy$ = new Subject<void>();


	constructor(private productService: ProductService,
		private authService: MsalService,
		private msalBroadcastService: MsalBroadcastService,
		@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
		private loggerService: LoggerService) {

	}

	ngOnInit(): void {
		this.products = this.productService.getProducts();

		this.setLoggedIn();

		this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
		this.msalBroadcastService.msalSubject$
			.pipe(
				filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
			)
			.subscribe((result: EventMessage) => {
				if (this.authService.instance.getAllAccounts().length === 0) {
					window.location.pathname = "/";
				} else {
					this.setLoggedIn();
				}
			});

		this.msalBroadcastService.inProgress$
			.pipe(
				filter((status: InteractionStatus) => status === InteractionStatus.None),
				takeUntil(this.destroy$)
			)
			.subscribe(() => {
				this.setLoggedIn();
				this.checkAndSetActiveAccount();
			})

	}

	getProfileName(): string {
		return this.authService.instance.getActiveAccount()?.name ?? '';
	}

	setLoggedIn() {
		this.loggedIn = this.authService.instance.getAllAccounts().length > 0;
		if (this.loggedIn) {
			this.loggerService.log(`NavbarComponent: SetLoggedIn(): Calling SN_CSM_EC.onLogin()`);
			window.SN_CSM_EC.onLogin();
		} else {
			this.loggerService.log(`NavbarComponent: SetLoggedIn(): Calling SN_CSM_EC.onLogout()`);
			window.SN_CSM_EC.onLogout();
		}
	}

	checkAndSetActiveAccount() {
		/**
		 * If no active account set but there are accounts signed in, sets first account to active account
		 * To use active account set here, subscribe to inProgress$ first in your component
		 * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
		 */
		let activeAccount = this.authService.instance.getActiveAccount();

		if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
			let accounts = this.authService.instance.getAllAccounts();
			this.authService.instance.setActiveAccount(accounts[0]);
		}
	}

	loginRedirect() {
		if (this.msalGuardConfig.authRequest) {
			this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
		} else {
			this.authService.loginRedirect();
		}
	}

	loginPopup() {
		if (this.msalGuardConfig.authRequest) {
			this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
				.subscribe((response: AuthenticationResult) => {
					this.authService.instance.setActiveAccount(response.account);
				});
		} else {
			this.authService.loginPopup()
				.subscribe((response: AuthenticationResult) => {
					this.authService.instance.setActiveAccount(response.account);
				});
		}
	}

	logout(popup?: boolean) {
		if (popup) {
			this.authService.logoutPopup({
				mainWindowRedirectUri: "/"
			});
		} else {
			this.authService.logoutRedirect();
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next(undefined);
		this.destroy$.complete();
	}

}

<div class="card">
    <div class="card-body">
        <h5 class="card-title">Profile Information</h5>
        <p><strong>First Name: </strong> {{profile?.givenName}}</p>
        <p><strong>Last Name: </strong> {{profile?.surname}}</p>
        <p><strong>Email: </strong> {{profile?.userPrincipalName}}</p>
        <p><strong>Id: </strong> {{profile?.id}}</p>    
        <a href="#" class="btn btn-warning" (click)="logout(true)">Logout</a>
    </div>
</div>

<nav class="navbar navbar-expand-lg navbar-light bg-white p-3">
    <div class="container-fluid">
        <a class="navbar-brand" href="#" [routerLink]="['']">
            <img
                src="assets/images/siemenshealthineers.png"
                class="d-inline-block align-top brand-logo"
                alt="Siemens Healthineers"
            />
        </a>

        <ul class="navbar-nav ml-auto">
            <li *ngFor="let product of products" class="nav-item">
                <a
                    class="nav-link"
                    href="#"
                    [routerLink]="['product', product.slug]"
                    >{{ product.name }}</a
                >
            </li>
            <li class="nav-item">
                <a 
                    class="nav-link"
                    href="#"
                    [routerLink]="['portal']"
                    >Help Portal</a>
            </li>
            <li class="nav-item">
                <!-- <a class="nav-link" href="#" [routerLink]="['profile']">Profile</a> -->
                <a
                    *ngIf="!loggedIn"
                    class="nav-link"
                    href="#"
                    (click)="loginPopup()"
                    >Login</a
                >
                <div *ngIf="loggedIn" class="btn-group" dropdown>
                    <button
                        dropdownToggle
                        type="button"
                        class="btn btn-light dropdown-toggle"
                        aria-controls="dropdown-basic"
                    >
                        {{ getProfileName() }}&nbsp;<span class="caret"></span>
                    </button>
                    <ul
                        id="dropdown-basic"
                        *dropdownMenu
                        class="dropdown-menu dropdown-menu-right"
                        role="menu"
                        aria-labelledby="button-basic"
                    >
                        <li role="menuitem">
                            <a
                                class="dropdown-item"
                                href="#"
                                [routerLink]="['profile']"
                                >Profile</a
                            >
                        </li>
                        <li class="divider dropdown-divider"></li>
                        <li role="menuitem">
                            <a
                                class="dropdown-item"
                                href="#"
                                (click)="logout(true)"
                                >Logout</a
                            >
                        </li>
                    </ul>
                </div>
            </li>
        </ul>
    </div>
</nav>

import { Injectable } from '@angular/core';

const logColorCss = "color: LightSeaGreen;";

@Injectable({
	providedIn: 'root'
})
export class LoggerService {

	constructor() { }

	public log(msg: string): void {
		console.log(`%c${msg}`, logColorCss);
	}

	public info(msg: string): void {
		console.info(`%c${msg}`, logColorCss);
	}

	public warn(msg: string): void {
		console.warn(`%c${msg}`, logColorCss);
	}

	public error(msg: string): void {
		console.error(`%c${msg}`, logColorCss);
	}
}

import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Subject, takeUntil } from 'rxjs';
import { Profile } from 'src/types/profile.type';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me'; // Prod graph endpoint. Uncomment to use.

@Component({
	selector: 'shsis-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
	profile?: Profile;
	destroy$ = new Subject<void>();

	constructor(private http: HttpClient,
		private authService: MsalService) { }

	ngOnInit() {
		this.http.get(GRAPH_ENDPOINT).pipe(
			takeUntil(this.destroy$)
		).subscribe(profile => {
			this.profile = profile;
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}


	logout(popup?: boolean) {
		if (popup) {
			this.authService.logoutPopup({
				mainWindowRedirectUri: "/"
			});
		} else {
			this.authService.logoutRedirect();
		}
	}

}

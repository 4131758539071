import { Component, OnDestroy, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { Subject, takeUntil } from 'rxjs';
import { LoggerService } from '../logger.service';

@Component({
	selector: 'shsis-saml-callback',
	templateUrl: './saml-callback.component.html',
	styleUrls: ['./saml-callback.component.scss']
})
export class SamlCallbackComponent implements OnInit, OnDestroy {
	private destroy$ = new Subject<void>();

	constructor(private readonly authService: MsalService,
		private loggerService: LoggerService) { }

	ngOnInit(): void {
		this.authService.handleRedirectObservable().pipe(
			takeUntil(this.destroy$),
		).subscribe(
			(result: AuthenticationResult) => {
				this.loggerService.log(`SamlCallbackComponent: ngOnInit: received authentication result: ${result}`);
			}
		);
	}

	ngOnDestroy(): void {
		this.loggerService.log(`SamlCallbackComponent: ngOnDestroy`);
		this.destroy$.next();
		this.destroy$.complete();
	}
}

import { LogLevel } from '@azure/msal-browser';

const logColorCss = "color: orange;";

export function msalLoggerCallback(logLevel: LogLevel, message: string) {
	switch (logLevel) {
		case LogLevel.Error:
			console.error(`%cMsalLogger: Error: ${message}`, logColorCss);
			return;
		case LogLevel.Info:
			console.info(`%cMsalLogger: Info: ${message}`, logColorCss);
			return;
		case LogLevel.Verbose:
			console.debug(`%cMsalLogger: Verbose: ${message}`, logColorCss);
			return;
		case LogLevel.Warning:
			console.warn(`%cMsalLogger: Warning: ${message}`, logColorCss);
			return;
		default:
			console.log(`%cMsalLogger: Unknown (${logLevel}): ${message}`, logColorCss);
			return;
	}
}

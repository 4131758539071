import { IPublicClientApplication, PublicClientApplication, BrowserCacheLocation } from '@azure/msal-browser';
import { msalLoggerCallback } from './MSALLoggerCallback';


export function MSALInstanceFactory(): IPublicClientApplication {
	return new PublicClientApplication({
		auth: {
			clientId: 'ea762e9d-dec1-4366-9c63-b5a709c4ad9b',
			authority: 'https://login.microsoftonline.com/115c3f52-0d56-4a77-92fa-3153431bf3e0/',
			redirectUri: '/',
			postLogoutRedirectUri: '/'
		},
		cache: {
			cacheLocation: BrowserCacheLocation.LocalStorage,
			storeAuthStateInCookie: true // might be unnecessary, let's figure that out once everything works
		},
		system: {
			loggerOptions: {
				loggerCallback: msalLoggerCallback,
				// logLevel: LogLevel.Info,
				piiLoggingEnabled: true
			}
		}
	});
}

import { Component, Input, OnInit } from '@angular/core';
import { Product } from 'src/types/product.type';

@Component({
	selector: 'shsis-product-teaser',
	templateUrl: './product-teaser.component.html',
	styleUrls: ['./product-teaser.component.scss']
})
export class ProductTeaserComponent implements OnInit {
	@Input() product?: Product;

	constructor() { }

	ngOnInit(): void {
	}

}

<div class="row">
	<div class="col">
		<img
			class="product-img"
			[src]="product?.image"
			[alt]="product?.name"
		/>
	</div>
</div>
<div class="row">
	<div class="col">
		<h5>{{product?.name}}</h5>
	</div>
</div>
<div class="row">
	<div class="col">
		<a href="#" class="btn btn-primary" [routerLink]="['']">Back to overview</a>
	</div>
</div>
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Product } from 'src/types/product.type';
import { ProductService } from '../product.service';

@Component({
	selector: 'shsis-product',
	templateUrl: './product.component.html',
	styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit, OnDestroy {
	product?: Product;

	paramMap$?: Subscription;

	constructor(private activatedRoute: ActivatedRoute,
		private productService: ProductService) { }

	ngOnInit(): void {
		this.paramMap$ = this.activatedRoute.paramMap.subscribe((params) => {
			const slug = params.get('slug') ?? '';
			this.product = this.productService.getBySlug(slug);
		});
	}

	ngOnDestroy(): void {
		if (typeof this.paramMap$ !== 'undefined') {
			this.paramMap$.unsubscribe();
		}
	}

}

import { InteractionType } from '@azure/msal-browser';
import { MsalGuardConfiguration } from '@azure/msal-angular';


export function MSALGuardConfigFactory(): MsalGuardConfiguration {
	return {
		interactionType: InteractionType.Redirect,
		authRequest: {
			scopes: ['user.read']
		},
		loginFailedRoute: '/login-failed'
	};
}

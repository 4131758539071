import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ProfileComponent } from './profile/profile.component';
import { ProductComponent } from './product/product.component';
import { FailedComponent } from './failed/failed.component';
import { ProductTeaserComponent } from './product-teaser/product-teaser.component';
import { ProductOverviewComponent } from './product-overview/product-overview.component';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SamlCallbackComponent } from './saml-callback/saml-callback.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PortalComponent } from './portal/portal.component';
import { MSALGuardConfigFactory } from './msal/MSALGuardConfigFactory';
import { MSALInterceptorConfigFactory } from './msal/MSALInterceptorConfigFactory';
import { MSALInstanceFactory } from './msal/MSALInstanceFactory';

@NgModule({
	declarations: [
		AppComponent,
		NavbarComponent,
		ProfileComponent,
		ProductComponent,
		FailedComponent,
		ProductTeaserComponent,
		ProductOverviewComponent,
		SamlCallbackComponent,
		PortalComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		MsalModule,
		BrowserAnimationsModule,
		BsDropdownModule.forRoot(),
		HttpClientModule,
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: MsalInterceptor,
			multi: true
		},
		{
			provide: MSAL_INSTANCE,
			useFactory: MSALInstanceFactory
		},
		{
			provide: MSAL_GUARD_CONFIG,
			useFactory: MSALGuardConfigFactory
		},
		{
			provide: MSAL_INTERCEPTOR_CONFIG,
			useFactory: MSALInterceptorConfigFactory
		},
		MsalService,
		MsalGuard,
		MsalBroadcastService
	],
	bootstrap: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

import { Injectable } from '@angular/core';
import { Product } from 'src/types/product.type';

const products: Product[] = [
{
	id: 0,
	name: "Cios Spin",
	slug: "cios-spin",
	image: "assets/images/cios_spin.jpg"
},
{
	id: 1,
	name: "Magnetom Sola",
	slug: "magnetom-sola",
	image: "assets/images/magnetom_sola.jpg"
},
{
	id: 2,
	name: "Precision OS",
	slug: "precision-os",
	image: "assets/images/virtualreality.jpg"
}

];

@Injectable({
	providedIn: 'root'
})
export class ProductService {

	constructor() { }

	getProducts(): Product[] {
		return products;
	}

	getById(id: number): Product | undefined {
		return products.find((product) => product.id === id);
	}

	getBySlug(productName: string): Product | undefined {
		return products.find((product) => product.slug === productName);
	}
}

<div class="col">
    <div class="card">
        <div class="card__overlay"></div>
        <div class="card__content">
            <a
                href="#"
                [routerLink]="['product', product?.slug]"
                ><img
                    class="teaser-img card-img-top"
                    [src]="product?.image"
                    [alt]="product?.name"
            /></a>
            <div class="card-body">
                <h5 class="card-title" style="height: 1rem">
                    {{ product?.name }}
                </h5>
                <p class="card-text">Details for {{ product?.name }}</p>
                <a
                    href="#"
                    class="btn btn-primary"
                    [routerLink]="['product', product?.slug]"
                    >View</a
                >
            </div>
        </div>
    </div>
</div>

import { InteractionType } from '@azure/msal-browser';
import { MsalInterceptorConfiguration } from '@azure/msal-angular';


export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
	const protectedResourceMap = new Map<string, Array<string>>();
	protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

	return {
		interactionType: InteractionType.Redirect,
		protectedResourceMap
	};
}

import { Component, OnInit } from '@angular/core';
import { Product } from 'src/types/product.type';
import { ProductService } from '../product.service';

@Component({
	selector: 'shsis-product-overview',
	templateUrl: './product-overview.component.html',
	styleUrls: ['./product-overview.component.scss']
})
export class ProductOverviewComponent implements OnInit {
	numCols = 'row-cols-3';
	products: Product[] = [];

	constructor(private productService: ProductService) { }

	ngOnInit(): void {
		this.products = this.productService.getProducts();
	}
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { filter, Subject, takeUntil } from 'rxjs';
import { LoggerService } from './logger.service';

@Component({
	selector: 'shsis-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
	title = 'shsis';
	isIframe = false;

	destroy$ = new Subject<void>();

	constructor(private msalBroadcastService: MsalBroadcastService,
		private authService: MsalService,
		private loggerService: LoggerService) {

	}

	ngOnInit() {
		this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal

		this.msalBroadcastService.msalSubject$.pipe(
			takeUntil(this.destroy$),
			filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
		).subscribe((result: EventMessage) => {
			this.loggerService.log("AppComponent: ngOnInit: Auth result:" + JSON.stringify(result));
			const payload = result.payload as AuthenticationResult;
			this.authService.instance.setActiveAccount(payload.account);
		});

		this.msalBroadcastService.inProgress$.pipe(
			takeUntil(this.destroy$),
			filter((status: InteractionStatus) => status === InteractionStatus.None)
		).subscribe(
			() => {
				this.loggerService.log(`AppComponent: ngOnInit: ${this.authService.instance.getAllAccounts().length} user(s) logged in`);
			}
		);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
